import { Card, CardContent } from '@app/@/components/ui/card'
import { type Translate } from 'next-translate'
import { UpArrow } from './icons/prev'

export const WarningBlock = ({ t, count }: { count: number; t: Translate }) => {
  return (
    <Card>
      <div className='flex gap-3 flex-1 pt-6'>
        <div className='flex-1'>
          <CardContent className='space-y-3'>
            <h4 className='font-bold text-lg flex gap-2 items-center'>
              {t('num-warnings')}
            </h4>

            <h5 className='text-gray-600 dark:text-gray-300 leading-8 text-base'>
              {count || 0}
            </h5>
            <div className='font-bold flex gap-2 items-center text-xs'>
              <div className='flex items-center gap-1 text-green-600 dark:text-green-700'>
                {count ? <UpArrow /> : null}
                <p className='text-xs'>{count || 0}</p>
              </div>
              {t('vs-prev')}
            </div>
          </CardContent>
        </div>
        <div className='px-4'>
          <svg
            width='48'
            height='48'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              width='48'
              height='48'
              rx='24'
              className='fill-yellow-100 dark:fill-yellow-900'
            />
            <path
              d='M24 26.75C23.59 26.75 23.25 26.41 23.25 26V21C23.25 20.59 23.59 20.25 24 20.25C24.41 20.25 24.75 20.59 24.75 21V26C24.75 26.41 24.41 26.75 24 26.75Z'
              className='fill-yellow-500 dark:fill-yellow-300'
            />
            <path
              d='M24 29.9996C23.94 29.9996 23.87 29.9896 23.8 29.9796C23.74 29.9696 23.68 29.9496 23.62 29.9196C23.56 29.8996 23.5 29.8696 23.44 29.8296C23.39 29.7896 23.34 29.7496 23.29 29.7096C23.11 29.5196 23 29.2596 23 28.9996C23 28.7396 23.11 28.4796 23.29 28.2896C23.34 28.2496 23.39 28.2096 23.44 28.1696C23.5 28.1296 23.56 28.0996 23.62 28.0796C23.68 28.0496 23.74 28.0296 23.8 28.0196C23.93 27.9896 24.07 27.9896 24.19 28.0196C24.26 28.0296 24.32 28.0496 24.38 28.0796C24.44 28.0996 24.5 28.1296 24.56 28.1696C24.61 28.2096 24.66 28.2496 24.71 28.2896C24.89 28.4796 25 28.7396 25 28.9996C25 29.2596 24.89 29.5196 24.71 29.7096C24.66 29.7496 24.61 29.7896 24.56 29.8296C24.5 29.8696 24.44 29.8996 24.38 29.9196C24.32 29.9496 24.26 29.9696 24.19 29.9796C24.13 29.9896 24.06 29.9996 24 29.9996Z'
              className='fill-yellow-500 dark:fill-yellow-300'
            />
            <path
              d='M30.0605 34.1598H17.9405C15.9905 34.1598 14.5005 33.4498 13.7405 32.1698C12.9905 30.8898 13.0905 29.2398 14.0405 27.5298L20.1005 16.6298C21.1005 14.8298 22.4805 13.8398 24.0005 13.8398C25.5205 13.8398 26.9005 14.8298 27.9005 16.6298L33.9605 27.5398C34.9105 29.2498 35.0205 30.8898 34.2605 32.1798C33.5005 33.4498 32.0105 34.1598 30.0605 34.1598ZM24.0005 15.3398C23.0605 15.3398 22.1405 16.0598 21.4105 17.3598L15.3605 28.2698C14.6805 29.4898 14.5705 30.6098 15.0405 31.4198C15.5105 32.2298 16.5505 32.6698 17.9505 32.6698H30.0705C31.4705 32.6698 32.5005 32.2298 32.9805 31.4198C33.4605 30.6098 33.3405 29.4998 32.6605 28.2698L26.5905 17.3598C25.8605 16.0598 24.9405 15.3398 24.0005 15.3398Z'
              className='fill-yellow-500 dark:fill-yellow-300'
            />
          </svg>
        </div>
      </div>
    </Card>
  )
}
