import { Card, CardContent } from '@app/@/components/ui/card'
import { Website } from '@app/types'
import { format } from 'date-fns'
import { Translate } from 'next-translate'
import { UpArrow } from './icons/prev'

export const LeaderboardsBlock = ({
  website,
  t,
}: {
  website: Website
  t: Translate
}) => {
  return (
    <Card>
      <div>
        <div className='flex gap-3 items-center border-b px-3 py-3'>
          <svg
            width='36'
            height='36'
            viewBox='0 0 36 36'
            xmlns='http://www.w3.org/2000/svg'
            className='fill-yellow-500 dark:fill-yellow-800'
          >
            <rect width='36' height='36' rx='6' />
            <path
              d='M15.2243 26.9587H9.66602C9.32435 26.9587 9.04102 26.6753 9.04102 26.3337V21.3337C9.04102 20.067 10.066 19.042 11.3327 19.042H15.2243C15.566 19.042 15.8493 19.3253 15.8493 19.667V26.3337C15.8493 26.6753 15.566 26.9587 15.2243 26.9587ZM10.291 25.7087H14.5993V20.292H11.3327C10.7577 20.292 10.291 20.7587 10.291 21.3337V25.7087Z'
              className='fill-white'
            />
            <path
              d='M20.7751 26.958H15.2168C14.8751 26.958 14.5918 26.6747 14.5918 26.333V17.9997C14.5918 16.733 15.6168 15.708 16.8835 15.708H19.1085C20.3751 15.708 21.4001 16.733 21.4001 17.9997V26.333C21.4001 26.6747 21.1251 26.958 20.7751 26.958ZM15.8501 25.708H20.1585V17.9997C20.1585 17.4247 19.6918 16.958 19.1168 16.958H16.8918C16.3168 16.958 15.8501 17.4247 15.8501 17.9997V25.708Z'
              className='fill-white'
            />
            <path
              d='M26.3337 26.9587H20.7754C20.4337 26.9587 20.1504 26.6753 20.1504 26.3337V22.167C20.1504 21.8253 20.4337 21.542 20.7754 21.542H24.6671C25.9337 21.542 26.9587 22.567 26.9587 23.8337V26.3337C26.9587 26.6753 26.6754 26.9587 26.3337 26.9587ZM21.4004 25.7087H25.7087V23.8337C25.7087 23.2587 25.2421 22.792 24.6671 22.792H21.4004V25.7087Z'
              className='fill-white'
            />
            <path
              d='M19.4164 14.9579C19.2164 14.9579 18.9664 14.8995 18.6831 14.7329L17.9997 14.3245L17.3247 14.7245C16.6414 15.1329 16.1914 14.8912 16.0247 14.7745C15.8581 14.6579 15.4997 14.2912 15.6747 13.5245L15.8331 12.8329L15.2664 12.2662C14.9164 11.9162 14.7914 11.4995 14.9164 11.1162C15.0414 10.7329 15.3831 10.4662 15.8664 10.3829L16.5914 10.2579L16.9997 9.44121C17.4497 8.54954 18.5414 8.54954 18.9831 9.44121L19.3914 10.2579L20.1164 10.3829C20.5997 10.4662 20.9497 10.7329 21.0664 11.1162C21.1914 11.4995 21.0581 11.9162 20.7164 12.2662L20.1497 12.8329L20.3081 13.5245C20.4831 14.2995 20.1247 14.6579 19.9581 14.7829C19.8831 14.8495 19.6914 14.9579 19.4164 14.9579ZM17.9997 13.0662C18.1997 13.0662 18.3997 13.1162 18.5664 13.2162L19.0331 13.4912L18.9331 13.0412C18.8497 12.6912 18.9747 12.2662 19.2331 12.0079L19.6581 11.5829L19.1331 11.4912C18.7997 11.4329 18.4747 11.1912 18.3247 10.8912L17.9997 10.2662L17.6831 10.8912C17.5331 11.1912 17.2081 11.4329 16.8747 11.4912L16.3497 11.5745L16.7747 11.9995C17.0331 12.2579 17.1497 12.6829 17.0747 13.0329L16.9747 13.4829L17.4414 13.2079C17.5997 13.1079 17.7997 13.0662 17.9997 13.0662Z'
              className='fill-white'
            />
          </svg>

          <h4 className='font-bold text-lg flex gap-2 items-center'>
            {t('leaderboard-rank')}
          </h4>
        </div>
      </div>
      <CardContent className='space-y-3 py-6 overflow-hidden'>
        <div>
          <div className='space-y-2'>
            <div className='text-xl font-bold'>
              {format(new Date(), 'MMMM yyyy')}
            </div>
            <div className='flex gap-3 place-content-between items-center flex-wrap'>
              <h5 className='text-gray-600 dark:text-gray-300 leading-8 text-3xl'>
                {website.issuesInfo?.accessScore || 0}
                <span className='text-lg'>/100</span>
              </h5>
              <div className='font-bold flex gap-2 items-center text-base'>
                <div className='flex items-center gap-1 text-green-600 dark:text-green-700'>
                  {website.issuesInfo?.accessScore ? <UpArrow /> : null}
                  <p className='text-xs'>
                    {website.issuesInfo?.accessScore || 0}
                  </p>
                </div>
                {t('vs-prev')}
              </div>
            </div>
            <div className='text-lg text-gray-600 dark:text-gray-300'>
              {t('global-rank')}
            </div>
            <div>
              <div className='flex gap-3 place-content-between items-center flex-wrap'>
                <h5 className='text-gray-600 dark:text-gray-300 leading-8 text-3xl'>
                  {website.issuesInfo?.accessScoreAverage || 0}
                  <span className='text-lg'>/100</span>
                </h5>
                <div className='font-bold flex gap-2 items-center text-base'>
                  <div className='flex items-center gap-1 text-green-600 dark:text-green-700'>
                    <svg
                      width='16'
                      height='16'
                      viewBox='0 0 12 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <mask
                        id='mask0_836_131300'
                        maskUnits='userSpaceOnUse'
                        x='0'
                        y='0'
                        width='24'
                        height='24'
                      >
                        <rect width='24' height='24' fill='#D9D9D9' />
                      </mask>
                      <g mask='url(#mask0_836_131300)'>
                        <path
                          d='M10.0004 4.7125L7.76289 6.9375C7.47122 7.22917 7.11706 7.375 6.70039 7.375C6.28372 7.375 5.92956 7.22917 5.63789 6.9375L5.05039 6.35C4.95872 6.25833 4.84206 6.2125 4.70039 6.2125C4.55872 6.2125 4.44206 6.25833 4.35039 6.35L2.05039 8.65C1.95872 8.74167 1.84206 8.7875 1.70039 8.7875C1.55872 8.7875 1.44206 8.74167 1.35039 8.65C1.25872 8.55833 1.21289 8.44167 1.21289 8.3C1.21289 8.15833 1.25872 8.04167 1.35039 7.95L3.65039 5.65C3.94206 5.35833 4.29622 5.2125 4.71289 5.2125C5.12956 5.2125 5.48372 5.35833 5.77539 5.65L6.35039 6.225C6.45039 6.325 6.56914 6.375 6.70664 6.375C6.84414 6.375 6.96289 6.325 7.06289 6.225L9.28789 4H8.50039C8.35872 4 8.23997 3.95208 8.14414 3.85625C8.04831 3.76042 8.00039 3.64167 8.00039 3.5C8.00039 3.35833 8.04831 3.23958 8.14414 3.14375C8.23997 3.04792 8.35872 3 8.50039 3H10.5004C10.6421 3 10.7608 3.04792 10.8566 3.14375C10.9525 3.23958 11.0004 3.35833 11.0004 3.5V5.5C11.0004 5.64167 10.9525 5.76042 10.8566 5.85625C10.7608 5.95208 10.6421 6 10.5004 6C10.3587 6 10.24 5.95208 10.1441 5.85625C10.0483 5.76042 10.0004 5.64167 10.0004 5.5V4.7125Z'
                          fill='#08AF04'
                        />
                      </g>
                    </svg>
                    <p className='text-xs'>
                      {website.issuesInfo?.accessScoreAverage}
                    </p>
                  </div>
                  {t('vs-prev')}
                </div>
              </div>
              <div className='text-lg text-gray-600 dark:text-gray-300'>
                {t('sector-rank')}
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
