import { P } from '@app/components/stateless/typo/p'
import { getStepBtnStyle } from './styles'
import { CompletedCheck } from './completed-check'

export const StepFourMenuItem = ({
  active,
  completed,
}: {
  active?: boolean
  completed?: boolean
}) => {
  return (
    <li className='flex gap-2 min-w-[10rem]'>
      <div>
        {completed && !active ? (
          <CompletedCheck />
        ) : (
          <div className={getStepBtnStyle(active)}>
            <svg
              width='28'
              height='28'
              viewBox='0 0 28 28'
              xmlns='http://www.w3.org/2000/svg'
              className={active ? 'fill-blue-700' : 'fill-gray-700'}
            >
              <path d='M2.33398 11.3747C1.85565 11.3747 1.45898 10.978 1.45898 10.4997V7.58301C1.45898 4.19967 4.21232 1.45801 7.58398 1.45801H10.5007C10.979 1.45801 11.3757 1.85467 11.3757 2.33301C11.3757 2.81134 10.979 3.20801 10.5007 3.20801H7.58398C5.16898 3.20801 3.20898 5.16801 3.20898 7.58301V10.4997C3.20898 10.978 2.81232 11.3747 2.33398 11.3747Z' />
              <path d='M25.6667 11.3747C25.1883 11.3747 24.7917 10.978 24.7917 10.4997V7.58301C24.7917 5.16801 22.8317 3.20801 20.4167 3.20801H17.5C17.0217 3.20801 16.625 2.81134 16.625 2.33301C16.625 1.85467 17.0217 1.45801 17.5 1.45801H20.4167C23.7883 1.45801 26.5417 4.19967 26.5417 7.58301V10.4997C26.5417 10.978 26.145 11.3747 25.6667 11.3747Z' />
              <path d='M20.416 26.542H18.666C18.1877 26.542 17.791 26.1453 17.791 25.667C17.791 25.1887 18.1877 24.792 18.666 24.792H20.416C22.831 24.792 24.791 22.832 24.791 20.417V18.667C24.791 18.1887 25.1877 17.792 25.666 17.792C26.1443 17.792 26.541 18.1887 26.541 18.667V20.417C26.541 23.8003 23.7877 26.542 20.416 26.542Z' />
              <path d='M10.5007 26.5417H7.58398C4.21232 26.5417 1.45898 23.8 1.45898 20.4167V17.5C1.45898 17.0217 1.85565 16.625 2.33398 16.625C2.81232 16.625 3.20898 17.0217 3.20898 17.5V20.4167C3.20898 22.8317 5.16898 24.7917 7.58398 24.7917H10.5007C10.979 24.7917 11.3757 25.1883 11.3757 25.6667C11.3757 26.145 10.979 26.5417 10.5007 26.5417Z' />
              <path d='M16.3327 21.2913H11.666C8.84268 21.2913 7.29102 19.7397 7.29102 16.9163V11.083C7.29102 8.25967 8.84268 6.70801 11.666 6.70801H16.3327C19.156 6.70801 20.7077 8.25967 20.7077 11.083V16.9163C20.7077 19.7397 19.156 21.2913 16.3327 21.2913ZM11.666 8.45801C9.82268 8.45801 9.04102 9.23967 9.04102 11.083V16.9163C9.04102 18.7597 9.82268 19.5413 11.666 19.5413H16.3327C18.176 19.5413 18.9577 18.7597 18.9577 16.9163V11.083C18.9577 9.23967 18.176 8.45801 16.3327 8.45801H11.666Z' />
              <path d='M22.1673 14.875H5.83398C5.35565 14.875 4.95898 14.4783 4.95898 14C4.95898 13.5217 5.35565 13.125 5.83398 13.125H22.1673C22.6457 13.125 23.0423 13.5217 23.0423 14C23.0423 14.4783 22.6457 14.875 22.1673 14.875Z' />
            </svg>
          </div>
        )}
      </div>
      <div>
        <h4 className='xl:text-lg leading-tight'>Step 4/5</h4>

        <P className='text-xs md:text-sm text-muted-foreground'>
          Scan Frequency
        </P>
      </div>
    </li>
  )
}
