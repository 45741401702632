import { classNames } from '@app/utils/classes'

export const inputStyle = (activeSubscription?: boolean, noMin?: boolean) => {
  return classNames(
    'py-1 bg-transparent px-4 rounded-sm md:w-auto appearance-none min-w-[11rem]',
    noMin ? 'w-inherit' : 'flex-1 md:flex-none',
    !activeSubscription
      ? 'opacity-80'
      : 'focus:outline-none hover:bg-gray-200 dark:hover:bg-gray-800'
  )
}

export const selectStyle = (activeSubscription?: boolean) =>
  classNames(
    'outline-none relative inline-flex flex-shrink-0 h-4 w-7 rounded-full cursor-pointer transition-colors ease-in-out duration-200',
    'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
    !activeSubscription ? 'opacity-80' : ''
  )

export const labelStyle = 'text-sm flex-1 truncate md:flex-0 pl-1'
