import { FC } from 'react'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { Routes, companyName } from '@app/configs'
import { classNames } from '@app/utils/classes'
import { twitterBuilder } from '@app/lib/twitter-builder'
import { routeParseAbsolute } from '@app/lib/router-handler'
import { FixedCopyRight } from './fixed-copy-right'
import { GithubBadge } from '../badges'
import { ThemeSwitcher } from './themes/theme-switcher'
import { Translate } from 'next-translate'
import { GrRss } from 'react-icons/gr'
import { Link } from '@app/components/stateless/typo/link'
import { TranslateLanguageLink } from './navigation/translate-link'
import { IsoCertifiedBadge } from '../badges/iso-certified'
import { FILTERED_MARKETING } from '@app/configs/app-config'
import { W3CLogo } from '../stateless/svgs/w3c'
import { LeaderBoardSvgPattern } from '../marketing/leaderboards/banner'

interface NavLinks {
  className?: string
  filterType?: string
  blog?: boolean // if coming from the blog sub domain
  t: Translate
  lang: string
}

const NavLinks: FC<NavLinks> = ({ className, filterType, t, lang }) => {
  const routes = Routes.filter(({ type }: any) => type === filterType).reverse()

  return (
    <>
      {routes.map(({ href: link, name, mapper }) => {
        // make sure a valid link is found
        if (!link) {
          return null
        }

        const baseP = link[0] === '/'

        let href = link

        // route to absolute links
        if (baseP) {
          href = routeParseAbsolute(link, lang)
        }

        const outside = !baseP && link.includes('https')

        const linkText =
          outside && !mapper ? name : t(mapper || link.substring(1))

        return (
          <li key={href} className={'py-0.5'}>
            {['/blog', '/guides', '/web-accessibility-calculator'].includes(
              link
            ) ? (
              <a className={className} href={href}>
                {linkText}
              </a>
            ) : (
              <Link
                className={className}
                href={href}
                rel={outside ? 'noopener' : undefined}
                target={outside ? '_blank' : undefined}
                shouldPrefetch={false}
              >
                {linkText}
              </Link>
            )}
          </li>
        )
      })}
    </>
  )
}

// text-gray-700 dark:text-gray-200

const SectionLinks = ({
  title,
  blog,
  t,
  lang,
}: NavLinks & { title: string }) => {
  return (
    <div className={'flex-[0.2] pt-1 pb-4 md:pb-0'}>
      <h4 className={'font-semibold text-lg'}>{t(title)}</h4>
      <ul className={'list-none py-1 capitalize'}>
        <NavLinks
          filterType={title}
          className={
            'text-left leading-8hover:underline hover:text-blue-200 dark:hover:text-blue-200 focus:text-blue-500 dark:focus:text-blue-400'
          }
          blog={blog}
          t={t}
          lang={lang}
        />
      </ul>
    </div>
  )
}

// re-usable footer across application TODO: remove block condition css
const Footer = ({ sticky, blog }: { sticky?: boolean; blog?: boolean }) => {
  const { t, lang } = useTranslation('common')

  return (
    <footer
      className={classNames(
        'bg-gradient-to-r from-[#07253F] to-[#1261A5] text-white',
        'pt-24 pb-3.5',
        sticky ? `fixed bottom-0 left-0 right-0` : 'relative overflow-hidden'
      )}
    >
      <div className='px-2 md:px-8 lg:px-16 xl:px-22 container mx-auto max-w-screen-xl'>
        <div className='md:max-w-[1920px] ml-auto mr-auto px-4 pb-20'>
          <div className={'block md:flex md:gap-x-8 lg:gap-x-12'}>
            <div className={'flex-[0.2]'}>
              <div className='flex md:flex-col gap-x-4 md:gap-x-12 lg:gap-x-20 pb-8 md:pb-auto gap-y-6 place-content-between'>
                <div>
                  <p className={'font-bold text-3xl'}>{companyName}</p>
                  <p className='text-sm'>{t('footer-out')}</p>
                  <TranslateLanguageLink full left className='pt-4' />
                </div>
                {FILTERED_MARKETING ? null : (
                  <>
                    <IsoCertifiedBadge width={77} height={77} />
                    <W3CLogo t={t} />
                  </>
                )}
              </div>
            </div>
            <SectionLinks title={'explore'} blog={!!blog} t={t} lang={lang} />
            <SectionLinks title={'resources'} blog={!!blog} t={t} lang={lang} />
            <SectionLinks title={'company'} blog={!!blog} t={t} lang={lang} />
            <SectionLinks title={'legal'} blog={!!blog} t={t} lang={lang} />
          </div>
        </div>
        <div className='px-2 flex place-content-center place-items-center flex-col'>
          <FixedCopyRight />
          <ul className='flex gap-x-3 place-items-center pt-4 pb-3'>
            {process.env.NEXT_PUBLIC_GITHUB_URL ? (
              <li>
                <GithubBadge className='block text-3xl md:text-2xl hover:text-blue-600' />
              </li>
            ) : null}
            <li>
              <a
                href={`https://twitter.com/${twitterBuilder(lang)}`}
                rel='noreferrer'
                target='_blank'
                className='block text-3xl md:text-2xl hover:text-blue-600'
              >
                <span className='sr-only'>X - Twitter</span>
                <svg
                  fill='currentColor'
                  height='24'
                  width='24'
                  viewBox='0 0 22 20'
                >
                  <path d='M16.99 0H20.298L13.071 8.26L21.573 19.5H14.916L9.702 12.683L3.736 19.5H0.426L8.156 10.665L0 0H6.826L11.539 6.231L16.99 0ZM15.829 17.52H17.662L5.83 1.876H3.863L15.829 17.52Z'></path>
                </svg>
              </a>
            </li>
            <li>
              <a
                href={routeParseAbsolute('/rss', lang)}
                rel='noreferrer'
                target='_blank'
                className='block text-3xl md:text-2xl hover:text-blue-600'
              >
                <span className='sr-only'>RSS Logo</span>
                <GrRss className='grIcon text-2xl' />
              </a>
            </li>
          </ul>
          <div className='py-6'>
            <ThemeSwitcher />
          </div>
        </div>
        <div className='absolute -right-44 -top-48 pointer-events-none hidden md:block'>
          <LeaderBoardSvgPattern t={t} height={866} width={739} />
        </div>
      </div>
    </footer>
  )
}

export { Footer }
