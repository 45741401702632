import { SyntheticEvent, useMemo, useState } from 'react'
import { CaretSortIcon, InfoCircledIcon } from '@radix-ui/react-icons'

import { cn } from 'src/@/lib/utils'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@app/@/components/ui/popover'
import type { Translate } from 'next-translate'
import { Checkbox } from '@app/@/components/ui/checkbox'
import { Label } from '@app/@/components/ui/label'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@app/@/components/ui/tooltip'
import { Rule } from 'kayle/build/rules'
import { Input } from '@app/@/components/ui/input'
import { Button } from '@app/@/components/ui/button'
import { InfoBlock } from '../cells'

type IgnoreInputProps = {
  t: Translate
  disabled?: boolean
  onLoadRulesEvent: () => any
  ignoreList: Rule[]
  setIgnore: React.Dispatch<React.SetStateAction<string[]>>
  ignore: string[]
  cb?: (x: string[]) => void
  dialog?: boolean
  tooltip?: boolean
  activeSubscription?: boolean
  defaultValue?: string
}

export const IgnoreInput = ({
  t,
  disabled,
  onLoadRulesEvent,
  ignoreList,
  setIgnore,
  ignore,
  cb,
  dialog,
  tooltip,
  activeSubscription,
  defaultValue,
}: IgnoreInputProps) => {
  const [filterSearch, setFiltered] = useState<string>('')

  const onChangeFilterEvent = (e: SyntheticEvent<HTMLInputElement>) =>
    setFiltered(e.currentTarget.value)

  const { error, warning, notice } = useMemo(() => {
    return {
      error: t('error').toLowerCase(),
      warning: t('warning').toLowerCase(),
      notice: t('notice').toLowerCase(),
    }
  }, [t])

  // generic filter rules
  const onFilterEvent = (r: Rule) => {
    const s = filterSearch.toLowerCase()
    const rid = r.ruleId.toLowerCase()

    if (s === error) {
      return r.ruleType === 'error'
    }
    if (s === warning) {
      return r.ruleType === 'warning'
    }
    if (s === notice) {
      return r.ruleType === 'notice'
    }

    return rid.includes(s)
  }

  const filteredList = ignoreList?.filter(onFilterEvent)

  return (
    <InfoBlock
      tipID={`ignore-id`}
      toolTip={tooltip ? t('dashboard-inputs:rules-tip') : undefined}
      noPx={dialog}
      labelTip={<span>Rules Ignored</span>}
      noClass
    >
      <div className='w-full'>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              type='button'
              onClick={onLoadRulesEvent}
              className={`min-w-[11rem] border ltr:text-left rtl:text-right flex px-3 md:px-3 text-muted-foreground ${!activeSubscription ? ' opacity-80' : ''}`}
              variant={'ghost'}
              disabled={disabled}
            >
              {t('rules-set', { ruleCount: ignore.length })}
              <CaretSortIcon className='ml-2 h-4 w-4 shrink-0 opacity-50' />
            </Button>
          </PopoverTrigger>
          <PopoverContent className='p-0 w-screen md:w-[500px] min-w-full'>
            <div className='p-3'>
              <Input
                placeholder={t('filter-rules')}
                onChange={onChangeFilterEvent}
                defaultValue={defaultValue}
              />
            </div>
            <TooltipProvider>
              {filteredList.length ? (
                <ul className='flex-col flex gap-4 px-4 py-3 max-h-48 overflow-y-auto'>
                  {filteredList?.map((r) => (
                    <Tooltip key={r.ruleId}>
                      <div className='gap-3 flex flex-1 place-items-center'>
                        <Checkbox
                          defaultChecked={ignore.includes(r.ruleId)}
                          id={`rule-${r.ruleId}`}
                          onClick={() =>
                            setIgnore((igg = []) => {
                              const ig = [...igg]
                              const ind = Array.isArray(ig)
                                ? ig?.findIndex((f) => f === r.ruleId)
                                : -1

                              if (ind >= 0) {
                                ig?.splice(ind, 1)
                                if (cb) {
                                  cb(ig)
                                }
                              } else {
                                ig.push(r.ruleId)
                                if (cb) {
                                  cb(ig)
                                }
                              }
                              return ig
                            })
                          }
                        />
                        <div className='gap-2 flex flex-col flex-1'>
                          <Label
                            className='hover:text-gray-500 dark:hover:text-gray-300 max-w-[380px] truncate space-y-1'
                            htmlFor={`rule-${r.ruleId}`}
                          >
                            <div className='text-sm'>{r.ruleId}</div>
                            <div className='text-xs uppercase'>
                              {r.tags?.join(',')}{' '}
                              {r.ruleType ? `- ${t(r.ruleType || '')}` : ''}
                            </div>
                          </Label>
                        </div>

                        <TooltipTrigger asChild>
                          <InfoCircledIcon className={cn('ml-auto h-4 w-4')} />
                        </TooltipTrigger>
                      </div>

                      <TooltipContent>
                        <p>{r.description}</p>
                      </TooltipContent>
                    </Tooltip>
                  ))}
                </ul>
              ) : (
                <p className='px-4 py-3 max-h-48'>
                  {t('no-rules', { filterSearch: filterSearch })}
                </p>
              )}
            </TooltipProvider>
          </PopoverContent>
        </Popover>
      </div>
    </InfoBlock>
  )
}
