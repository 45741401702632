import { P } from '@app/components/stateless/typo/p'
import { getStepBtnStyle } from './styles'
import { CompletedCheck } from './completed-check'

export const StepThreeMenuItem = ({
  active,
  completed,
}: {
  active?: boolean
  completed?: boolean
}) => {
  return (
    <li className='flex gap-2 min-w-[10rem]'>
      <div>
        {completed && !active ? (
          <CompletedCheck />
        ) : (
          <div className={getStepBtnStyle(active)}>
            <svg
              width='28'
              height='28'
              viewBox='0 0 28 28'
              xmlns='http://www.w3.org/2000/svg'
              className={active ? 'fill-blue-700' : 'fill-gray-700'}
            >
              <path d='M25.666 8.45801H18.666C18.1877 8.45801 17.791 8.06134 17.791 7.58301C17.791 7.10467 18.1877 6.70801 18.666 6.70801H25.666C26.1443 6.70801 26.541 7.10467 26.541 7.58301C26.541 8.06134 26.1443 8.45801 25.666 8.45801Z' />
              <path d='M7.00065 8.45801H2.33398C1.85565 8.45801 1.45898 8.06134 1.45898 7.58301C1.45898 7.10467 1.85565 6.70801 2.33398 6.70801H7.00065C7.47898 6.70801 7.87565 7.10467 7.87565 7.58301C7.87565 8.06134 7.47898 8.45801 7.00065 8.45801Z' />
              <path d='M11.6673 12.5417C8.93732 12.5417 6.70898 10.3133 6.70898 7.58333C6.70898 4.85333 8.93732 2.625 11.6673 2.625C14.3973 2.625 16.6257 4.85333 16.6257 7.58333C16.6257 10.3133 14.3973 12.5417 11.6673 12.5417ZM11.6673 4.375C9.89398 4.375 8.45898 5.81 8.45898 7.58333C8.45898 9.35667 9.89398 10.7917 11.6673 10.7917C13.4407 10.7917 14.8757 9.35667 14.8757 7.58333C14.8757 5.81 13.4407 4.375 11.6673 4.375Z' />
              <path d='M25.6667 21.292H21C20.5217 21.292 20.125 20.8953 20.125 20.417C20.125 19.9387 20.5217 19.542 21 19.542H25.6667C26.145 19.542 26.5417 19.9387 26.5417 20.417C26.5417 20.8953 26.145 21.292 25.6667 21.292Z' />
              <path d='M9.33398 21.292H2.33398C1.85565 21.292 1.45898 20.8953 1.45898 20.417C1.45898 19.9387 1.85565 19.542 2.33398 19.542H9.33398C9.81232 19.542 10.209 19.9387 10.209 20.417C10.209 20.8953 9.81232 21.292 9.33398 21.292Z' />
              <path d='M16.3333 25.3747C13.6033 25.3747 11.375 23.1463 11.375 20.4163C11.375 17.6863 13.6033 15.458 16.3333 15.458C19.0633 15.458 21.2917 17.6863 21.2917 20.4163C21.2917 23.1463 19.0633 25.3747 16.3333 25.3747ZM16.3333 17.208C14.56 17.208 13.125 18.643 13.125 20.4163C13.125 22.1897 14.56 23.6247 16.3333 23.6247C18.1067 23.6247 19.5417 22.1897 19.5417 20.4163C19.5417 18.643 18.1067 17.208 16.3333 17.208Z' />
            </svg>
          </div>
        )}
      </div>
      <div>
        <h4 className='xl:text-lg leading-tight'>Step 3/5</h4>
        <P className='text-xs md:text-sm text-muted-foreground'>
          Configuration of Site
        </P>
      </div>
    </li>
  )
}
