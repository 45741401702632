import { FC, memo } from 'react'
import { Website } from '@app/types'
import { Header4 } from '../header'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import ms from 'ms'

const WebsiteTableComponent: FC<{ websites: Website[] }> = ({ websites }) => {
  const { t } = useTranslation('dashboard')

  return (
    <div className='mx-auto py-4'>
      <div className='overflow-hidden border rounded-lg '>
        <div className='bg-blue-600 text-white px-6 relative py-0.5'>
          <Header4 className='text-lg xl:text-lg'>{t('site-details')}</Header4>
        </div>
        <div className='overflow-x-auto w-full'>
          <table className='min-w-full divide-y divide-gray-200'>
            <thead>
              <tr>
                <th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider'>
                  {t('site-url')}
                </th>
                <th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider'>
                  {t('total-errors')}
                </th>
                <th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider'>
                  {t('total-warnings')}
                </th>
                <th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider'>
                  {t('total-pages')}
                </th>
                <th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider'>
                  {t('sector-rank')}
                </th>
                <th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider'>
                  {t('crawl-duration')}
                </th>
                <th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider'>
                  {t('last-sync')}
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 overflow-auto'>
              {websites
                .map((website, index) => (
                  <tr key={index} className='hover:bg-gray-100'>
                    <td className='px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900'>
                      {website.url}
                    </td>
                    <td className='px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900'>
                      {website.issuesInfo?.errorCount || 0}
                    </td>
                    <td className='px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900'>
                      {website.issuesInfo?.warningCount || 0}
                    </td>
                    <td
                      className={`px-6 py-4 whitespace-no-wrap text-sm leading-5 font-semibold`}
                    >
                      {website?.issuesInfo?.pageCount || 0}
                    </td>
                    <td
                      className={`px-6 py-4 whitespace-no-wrap text-sm leading-5 font-semibold text-${getScoreColor(website.issuesInfo?.accessScoreAverage || 0)}`}
                    >
                      {typeof website.issuesInfo?.accessScoreAverage !==
                      'undefined'
                        ? `${website.issuesInfo?.accessScoreAverage}/100`
                        : 'N/A'}
                    </td>
                    <td className='px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900'>
                      {website.crawlDuration
                        ? ms(website.crawlDuration)
                        : 'N/A'}
                    </td>
                    <td className='px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900'>
                      {website.lastScanDate}
                    </td>
                  </tr>
                ))
                .toReversed()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const getScoreColor = (score: number) => {
  if (!score) {
    return 'gray-500'
  }

  if (score >= 75) {
    return 'green-600'
  }

  if (score >= 50) {
    return 'blue-600'
  }

  if (score >= 25) {
    return 'red-600'
  }

  return 'gray-500'
}

export const WebsiteTable = memo(WebsiteTableComponent)
